<!-- START TEAM -->
<section class="section" id="faq">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-comments title-icon text-muted"></i>
                  
                <h3 class="title">Frequently <span class="fw-bold">Asked</span> Questions (FAQs)</h3>
                <p class="text-muted mt-3 title-subtitle mx-auto">"Unlock knowledge and discover the answers you're looking for—explore our most frequently asked questions for detailed insights and clarity."</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-6">
                <div class="ps-4 pe-4">
                    <div class="mt-4">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. What is QuantumMine-X?</h5>
                        </div>
                        <div class="mt-3">
                            <p class="text-muted">Quantum Mine X is a project that focuses on selling servers to AI
                                companies to support their operations and projects. The project is currently in the
                                crowdfunding phase, where anyone can join the Quantum Mine X community by contributing
                                $5. Additionally, participants are incentivized to refer others to the community,
                                earning $1 for every successful referral.
                            </p>
                            <p class="text-muted">The project's goal is to build a community that supports the growth
                                and success of AI companies by providing them with the necessary server infrastructure.
                                Quantum Mine X also aims to expand its reach and impact through the collective effort of
                                its community members.
                            </p>
                        </div>
                    </div>
  
                    <div class="mt-5">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. How to Join?</h5>
                        </div>
                        <div class="mt-3">
                            <p class="text-muted">To join the Quantum Mine X community, follow these steps:</p>
                            <p class="text-muted">1. Visit the Quantum Mine X Website: Go to the official Quantum Mine X
                                website.</p>
                            <p class="text-muted">2. Locate the Telegram Group Link: On the landing page, find the link
                                to join the Quantum Mine X Telegram group.</p>
                            <p class="text-muted">3. Use the QMinex Bot: Click on the Telegram link, which will direct
                                you to the Telegram app. There, you'll interact with the QMinex bot.</p>
                            <p class="text-muted">4. Contribute $5: The QMinex bot will guide you through the process of
                                making a $5 contribution. This contribution is your entry fee to join the Quantum Mine X
                                community.</p>
                            <p class="text-muted">5. Complete the Process: Once the payment is confirmed, you'll
                                officially become a member of the Quantum Mine X community and gain access to the
                                Telegram group and its benefits.</p>
                            <p class="text-muted">6. Referral Opportunity: As a member, you can refer others to join the
                                community. For each successful referral, you earn $1.</p>
                            <p class="text-muted">This process ensures a streamlined entry into the community, fostering
                                easy engagement and growth.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="ps-4 pe-4">
                    <div class="mt-3">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. Whats our Goal?</h5>
                        </div>
                        <div class="mt-3">
                            <p class="text-muted">The goal of Quantum Mine X is to build a strong community that
                                supports the growth and success of AI companies by providing them with essential server
                                infrastructure. By selling servers to AI companies, Quantum Mine X aims to enable these
                                companies to achieve their project goals efficiently. The crowdfunding aspect of the
                                project not only helps raise the necessary funds but also fosters a community of
                                supporters who are invested in the success of AI technology.</p>
                            <p class="text-muted">Additionally, the referral system incentivizes community growth,
                                further expanding Quantum Mine X's reach and impact. Ultimately, the goal is to
                                establish Quantum Mine X as a key player in the AI infrastructure space, contributing to
                                the development and advancement of AI technologies.</p>
                        </div>
                    </div>
                    <div class="mt-4">
                        <div class="">
                            <h5 class="mb-0 f-18 font-weight-600">Q. Whats the benifit of joining Quantummine-X?</h5>
                        </div>
                        <div class="mt-3">
                            <p class="text-muted">As a participant in the Quantum Mine X community, your benefits
                                include:</p>
                            <p class="text-muted">1. Community Membership: By joining, you become part of a growing
                                community focused on supporting AI companies through server infrastructure, connecting
                                with like-minded individuals and experts in the field.</p>
                            <p class="text-muted">2. Referral Earnings: For every new member you refer to the Quantum
                                Mine X community, you earn $1. This can create a passive income stream as you help
                                expand the community.</p>
                            <p class="text-muted">3. Supporting AI Development: Your contribution helps fund the
                                infrastructure necessary for AI companies to thrive, playing a role in advancing AI
                                technology and its applications.</p>
                            <p class="text-muted">4. Access to Exclusive Information: As a member, you'll likely receive
                                updates on the project's progress, insights into the AI companies you're supporting, and
                                opportunities to engage more deeply with the initiative.</p>
                            <p class="text-muted">These benefits make joining the Quantum Mine X community not only a
                                potential financial opportunity but also a way to contribute to the future of AI
                                technology.</p>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END TEAM -->

<!-- START CTA -->
<section class="section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-center">
                    <h3 class="mb-4">You have another questions or need help?</h3>
                    <div class="">
                        <a [ngxScrollTo]="'#contact'"  href="javascript:void(0);" class="btn btn-round btn-primary">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END TEAM -->