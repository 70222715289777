<!-- START SERVICES -->
<section class="section" id="services">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-ruler-pencil title-icon text-muted"></i>
                <h3 class="title">Best <span class="fw-bold">Services</span></h3>
                <p class="text-muted mt-3 title-subtitle mx-auto">It is a long established fact that a reader will
                    be of a page when established fact looking at its layout.</p>
            </div>
        </div>
        <div class="row mt-5">
            @for(item of servicesData; track $index){
            <div class="col-md-4">
                <div class="services-box">
                    <div class="services-icon">
                        <i :class="{{item.icon}} text-primary"></i>
                    </div>
                    <div class="mt-3">
                        <h5 class="services-title fw-bold mb-3">{{item.title}}</h5>
                        <p class="services-subtitle text-muted"> Ut enim ad minim veniam, quis nostrud exercitation
                            ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                    </div>
                </div>
            </div>
            }
            <!-- end col -->
        </div>
        <!-- end row -->
    </div>
</section>
<!-- END SERVICES -->