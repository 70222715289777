<!-- CONTACT FORM START-->
<section class="section " id="contact">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-xl-6 col-10">
                <div class="text-center">
                    <i class="ti-headphone-alt title-icon text-muted"></i>
                    <h3 class="title">Get In <span class="fw-bold">Touch</span></h3>
                    <p class="text-muted mt-3">Every conversation begins with a single message. Reach out!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">
                <div class="mt-4 pt-4">
                    <p class="text-muted mt-4"><span class="fw-bold ">Office Address:</span><br> <span class="d-block mt-2">Reef Tower - Jumeirah Lake Towers - Dubai - United Arab Emirates</span></p>
                    <p class="text-muted mt-4"><span class="fw-bold ">Email Address:</span><br> <span class="d-block mt-2">contact&#64;quantumminex.com</span></p>
                </div>
            </div>
            <div class="col-lg-8">
                <div class="custom-form mt-4 pt-4">
                    <p class="text-muted mt-4">Reach out to us on Telegram for quick and direct communication. We're here to help!</p>
                    <div class="">
                        <a  href="https://t.me/+gbAtFcZ60XoxNGRl" target="_blank" class="btn btn-round btn-primary">Join Telegram</a>
                    </div>
                    <!-- <form method="post" (ngSubmit)="sendMsg()" [formGroup]="itemData">
                        <p id="error-msg"></p>
                        <div id="simple-msg"></div>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group mt-2">
                                    <input name="name" id="name" type="text" class="form-control" placeholder="Your name*" formControlName="name" required>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group mt-2">
                                    <input name="email" id="email" type="email" class="form-control" placeholder="Your email*" formControlName="email" required>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mt-2">
                                    <input type="text" class="form-control" id="subject" placeholder="Your Subject.." formControlName="subject"  required />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group mt-2">
                                    <textarea name="comments" id="comments" rows="4" class="form-control" placeholder="Your message..." formControlName="comments" required></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-end">
                                <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary" value="Send Message">
                                <div id="simple-msg"></div>
                            </div>
                        </div>
                    </form> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- CONTACT FORM END-->