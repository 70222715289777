<!-- Back to top -->
<div (window:scroll)="windowScroll()">
<a href="javascript:void(0);" [ngxScrollTo]="'#home'" class="back-to-top" id="back-to-top"> <i class="ti-angle-up"> </i> </a>
</div>
<!-- Style switcher -->
<!-- <div id="style-switcher" [style]="isVisible ? 'left: 0px' : 'left: -189px'">
    <div>
        <h3>Select your color</h3>
        <ul class="pattern">
            <li>
                <a class="color1" href="javascript: void(0);" (click)="setTheme('default')"></a>
            </li>
            <li>
                <a class="color2" href="javascript: void(0);" (click)="setTheme('pink')"></a>
            </li>
            <li>
                <a class="color3" href="javascript: void(0);" (click)="setTheme('yellow')"></a>
            </li>
            <li>
                <a class="color4" href="javascript: void(0);" (click)="setTheme('green')"></a>
            </li>
            <li>
                <a class="color5" href="javascript: void(0);" (click)="setTheme('purple')"></a>
            </li>
            <li>
                <a class="color6" href="javascript: void(0);" (click)="setTheme('light-blue')"></a>
            </li>
        </ul>
    </div>
    <div class="bottom">
        <a href="javascript: void(0);" class="settings" (click)="toggleSwitcher()"><i
                class="ti-settings ti-spin"></i></a>
    </div>
</div> -->
<!-- end Style switcher -->