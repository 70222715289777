<!-- START TEAM-->
<section class="section bg-light" id="team">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-hand-point-down title-icon text-muted"></i>
                <h3 class="title">Our <span class="fw-bold">Partners</span></h3>
                <!-- <p class="text-muted mt-3 title-subtitle mx-auto">It is a long established fact that a reader will
                    be of a page when established fact looking at its layout.</p> -->
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/partner/AAEON.png" alt=""
                            class="img-fluid  mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4 color">AAEON</p>
                        <!-- <p class="text-muted mt-4">You want customer to your store. Easily your coupans and has
                            Clooger.</p> -->
                    </div>

                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 active p-5">
                    <div class="">
                        <img src="assets/images/partner/advantech.jpeg" alt=""
                            class="img-fluid  mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">Advantech</p>
                        <!-- <p class="text-muted mt-4">You want customer to your store. Easily your coupans and has
                            Clooger.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/partner/openai.jpeg" alt=""
                            class="img-fluid  mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">Inter OpenVINO</p>
                        <!-- <p class="text-muted mt-4">
                            You want customer to your store. Easily your coupans and has
                            Clooger.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/partner/nvidia.png" alt=""
                            class="img-fluid  mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">NVIDIA</p>
                        <!-- <p class="text-muted mt-4">
                            You want customer to your store. Easily your coupans and has
                            Clooger.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/partner/Supermicro.png" alt=""
                            class="img-fluid  mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">Supermicro</p>
                        <!-- <p class="text-muted mt-4">
                            You want customer to your store. Easily your coupans and has
                            Clooger.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/partner/zoox.png" alt=""
                            class="img-fluid  mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">ZOOX</p>
                        <!-- <p class="text-muted mt-4">
                            You want customer to your store. Easily your coupans and has
                            Clooger.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/partner/c3.png" alt=""
                            class="img-fluid  mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">C3</p>
                        <!-- <p class="text-muted mt-4">
                            You want customer to your store. Easily your coupans and has
                            Clooger.</p> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/partner/aibee.png" alt=""
                            class="img-fluid  mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">Aibee</p>
                        <!-- <p class="text-muted mt-4">
                            You want customer to your store. Easily your coupans and has
                            Clooger.</p> -->
                    </div>
                </div>
            </div>
       
        </div>
    </div>
</section>
<!-- END TEAM -->