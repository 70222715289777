import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgParticlesModule } from "ng-particles";
import { CarouselModule } from 'ngx-owl-carousel-o';

import { PagesRoutingModule } from './pages-routing.module'

import { SharedModule } from '../shared/shared.module';
import { Index2Component } from './index2/index2.component';

@NgModule({
  declarations: [ Index2Component ],
  imports: [
    CommonModule,
    SharedModule,
    PagesRoutingModule,
    ScrollToModule.forRoot(),
    NgParticlesModule,
    CarouselModule
  ]
})
export class PagesModule { }
