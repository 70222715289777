import { Directive, Input, ElementRef, Renderer2, HostListener, OnInit } from '@angular/core';

@Directive({
    selector: '[appTypewriter]'
})
export class TypewriterDirective implements OnInit {
    @Input() appTypewriter: string[] = [];
    private currentIndex = 0;
    private currentText = '';
    private isDeleting = false;
    private typingSpeed = 100; // Adjust the typing speed as needed
    private pauseDuration = 1000; // Adjust the pause duration between sentences as needed
    private timer: any;

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        this.renderer.setStyle(this.el.nativeElement, 'height', '1.2em');
        this.renderer.setStyle(this.el.nativeElement, 'overflow', 'hidden');

        this.startTyping();
    }

    ngOnDestroy() {
        clearTimeout(this.timer);
    }

    private startTyping() {
        const index = this.currentIndex % this.appTypewriter.length;
        const fullText = this.appTypewriter[index];

        if (this.isDeleting) {
            this.currentText = fullText.substring(0, this.currentText.length - 1);
        } else {
            this.currentText = fullText.substring(0, this.currentText.length + 1);
        }

        this.renderer.setProperty(this.el.nativeElement, 'textContent', this.currentText);

        let typeSpeed = this.typingSpeed;

        if (this.isDeleting) {
            typeSpeed /= 2; // Adjust the backspacing speed as needed
        }

        if (!this.isDeleting && this.currentText === fullText) {
            this.isDeleting = true;
            typeSpeed = this.pauseDuration;
        } else if (this.isDeleting && this.currentText === '') {
            this.isDeleting = false;
            this.currentIndex++;
            typeSpeed = this.pauseDuration;
        }

        this.timer = setTimeout(() => this.startTyping(), typeSpeed);
    }
}