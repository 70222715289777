<!-- START TEAM-->
<section class="section bg-light" id="team">
    <div class="container">
        <div class="row justify-content-center text-center">
            <div class="col-lg-12">
                <i class="ti-user title-icon text-muted"></i>
                <h3 class="title">Our <span class="fw-bold">Team</span></h3>
                <p class="text-muted mt-3 title-subtitle mx-auto">Together, we achieve more—meet the people behind our
                    success.</p>
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Jaime Sevilla.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Jaime Sevilla</p>
                        <p class="text-muted">Director</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 active p-5">
                    <div class="">
                        <img src="assets/images/team/Tamay Besiroglu.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Tamay Besiroglu</p>
                        <p class="text-muted">Associate Director</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Maria De La Lama.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;María de la Lama</p>
                        <p class="text-muted">Head of Operations</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="../../../assets/images/team/David Owen.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;David Owen</p>
                        <p class="text-muted">Senior Researcher</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="../../../assets/images/team/Ege Erdil.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Ege Erdil</p>
                        <p class="text-muted">Senior Researcher</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Pablo Villalobos.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Pablo Villalobos</p>
                        <p class="text-muted">Staff Researcher</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Anson Ho.jpeg" alt=""
                            class="image-w img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Anson Ho</p>
                        <p class="text-muted">Staff Researcher</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Matthew Barnett.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Matthew Barnett</p>
                        <p class="text-muted">Staff Researcher</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Ben Cottier.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Ben Cottier</p>
                        <p class="text-muted">Staff Researcher</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Jean-Stanislas Denain.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Jean-Stanislas Denain</p>
                        <p class="text-muted">Staff Researcher</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Alexander Erben.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Alexander Erben</p>
                        <p class="text-muted">Staff Researcher</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Robi Rahman.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Robi Rahman</p>
                        <p class="text-muted">Data Scientist</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Josh You.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Josh You</p>
                        <p class="text-muted">Data Analyst</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Luke Frymire.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Luke Frymire</p>
                        <p class="text-muted">Data Analyst</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Robert Sandler.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Robert Sandler</p>
                        <p class="text-muted">Product Designer</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Edu Roldan.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Edu Roldán</p>
                        <p class="text-muted">Senior Software Engineer</p>

                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="text-center bg-white team-box mt-3 p-5">
                    <div class="">
                        <img src="assets/images/team/Lennart Heim.jpeg" alt=""
                            class="img-fluid rounded-circle mx-auto d-block">
                    </div>
                    <div class="team-name">
                        <p class="fw-bold mb-0 mt-4">&#64;Lennart Heim</p>
                        <p class="text-muted">Strategy Specialist</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- END TEAM -->